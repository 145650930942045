import { enableProdMode } from '@angular/core';
import { platformBrowser } from '@angular/platform-browser';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { WEBSITE_DOMAIN } from '@murdough-solutions/cms-common';
import { init, browserTracingIntegration, replayIntegration } from "@sentry/angular";

if (environment.production) {
  enableProdMode();
}

init({
  dsn: "https://73f70c9d1a2740a8a331c9ed01a04aca@o364348.ingest.sentry.io/4505018371604480",
  environment: environment.name,
  integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      replayIntegration(),
  ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    //tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // By default the SDK will attach those headers to all requests to localhost and same origin.
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

fetch(environment.domain_url).then(res => res.json()).then((data) => {
  platformBrowser([{ provide: WEBSITE_DOMAIN, useValue: data }]).bootstrapModule(AppModule);

}).catch(err => {
  console.error(err);
});


