import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { Angulartics2Module } from 'angulartics2';
import { NgxStripeModule } from 'ngx-stripe';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { CmsClientModule } from '@murdough-solutions/cms-client';
import { CmsCommonModule } from '@murdough-solutions/cms-common';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { environment } from 'src/environments/environment';
import { CmsCountrySelectComponent } from './components/cms-country-select/cms-country-select.component';
import { CmsLoadingOverlayComponent } from './components/cms-loading-overlay/cms-loading-overlay.component';
import { CustomBasicPage } from './custom-components/basic-page/basic-page';
import { CustomCampsCarousel } from './custom-components/camps-carousel/camps-carousel';
import { CustomCollegeDatabasePage } from './custom-components/college-database-page/college-database-page';
import { CustomCounselingPage } from './custom-components/counseling-page/counseling-page';
import { CustomCoxswainCoaching } from './custom-components/coxswain-coaching/coxswain-coaching';
import { CustomFooterMenu } from './custom-components/footer-menu/footer-menu';
import { CustomGapYearPage } from './custom-components/gap-year-page/gap-year-page';
import { CustomHomePage } from './custom-components/home-page/home-page';
import { CustomMainMenu } from './custom-components/main-menu/main-menu';
import { CustomPerformanceEnhancementPage } from './custom-components/performance-enhancement-page/performance-enhancement-page';
import { CustomSchedulingPage } from './custom-components/scheduling-page/scheduling-page';
import { CustomSparksStoryPage } from './custom-components/sparks-story-page/sparks-story-page';
import { CustomTestimonialsCarousel } from './custom-components/testimonials-carousel/testimonials-carousel';
import { CoreModule } from './modules/core/core.module';
import { IntercomService } from './services/intercom.service';

const isPrerender = window.navigator.userAgent?.includes('Murdough Solutions CMS')

@NgModule({
  declarations: [
    AppComponent,
    CmsCountrySelectComponent,
    CmsLoadingOverlayComponent,
    CustomBasicPage,
    CustomPerformanceEnhancementPage,
    CustomCoxswainCoaching,
    CustomHomePage,
    CustomSchedulingPage,
    CustomGapYearPage,
    CustomCounselingPage,
    CustomCollegeDatabasePage,
    CustomSparksStoryPage,
    CustomCampsCarousel,
    CustomFooterMenu,
    CustomMainMenu,
    CustomTestimonialsCarousel
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxStripeModule.forRoot(),
    MatProgressSpinnerModule,
    Angulartics2Module.forRoot({
      developerMode: !environment.production || isPrerender
    }),
    CmsCommonModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base,
      cdn_base: environment.cdn_base,
      default_date_format: 'PPP',
      default_date_time_format: 'PPP, p'
    }),
    CmsClientModule.forRoot({
      environment: environment.name,
      service_base: environment.service_base
    }),
    CoreModule.forRoot(),
    AppRoutingModule
  ],
  providers: [
    IntercomService,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: TraceService,
      deps: [Router]
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {


}
