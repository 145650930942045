import { Injectable } from "@angular/core"
import { IdentityService } from "@murdough-solutions/cms-common";

@Injectable()
export class IntercomService {

    constructor(
        private readonly identity: IdentityService
    ) {
        
    }

    public boot()
    {
        this.identity.decoded_token$.subscribe(token => {
            if (token) {
                (window as any).Intercom('boot', {
                    app_id: 'd85z9x4b',
                    email: token.email,
                    user_id: token.nameid,
                    name: token.given_name
                });

            }
            else {
                (window as any).Intercom('boot', {
                    app_id: 'd85z9x4b'
                });
            }
        });
    }
}