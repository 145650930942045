import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { CheckoutService } from 'src/app/modules/core/services/checkout.service';
import { GeocodeModel } from 'src/app/models/geocode.model';
import { CountryService, GoogleApiIntegrationMeta, WEBSITE_DOMAIN, WebsiteCountryModel, WebsiteDomainModel, WebsiteIntegrationType, countryComparer } from '@murdough-solutions/cms-common';

@Component({
  selector: 'cms-country-select',
  templateUrl: './cms-country-select.component.html',
  styleUrls: ['./cms-country-select.component.scss']
})
export class CmsCountrySelectComponent implements OnInit {
  public countries: Array<WebsiteCountryModel>;
  public current_country?: WebsiteCountryModel;
  public country_comparer = countryComparer;
  public google_meta?: GoogleApiIntegrationMeta;

  constructor(
    @Inject(WEBSITE_DOMAIN) private readonly domain: WebsiteDomainModel,
    private readonly http: HttpClient,
    private readonly country_service: CountryService,
    private readonly checkout: CheckoutService
  ) {
    this.countries = this.domain.website.countries;
    const integration = this.domain.website.integrations.find(f => f.type === WebsiteIntegrationType.GoogleAPI);
    if (integration) {
      this.google_meta = JSON.parse(integration.meta) as GoogleApiIntegrationMeta;
    }
  }

  ngOnInit(): void {
    this.current_country = this.countries.find(f => f.default_country);
    if (localStorage.getItem('country_id')) {
      const country = this.countries.find(f => f.country_id === localStorage.getItem('country_id'));
      if (country) {
        this.current_country = country;
      }
    }
    else if (this.google_meta) {
      const parent = this;

      navigator.geolocation.getCurrentPosition((position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        parent.http.get<GeocodeModel>('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&key=' + parent.google_meta!.api_key).subscribe({
          next: data => {
            // console.log(`Geo: ${JSON.stringify(data)}`);

            const result = data.results.find(f => f.address_components.find(ff => ff.types.includes('country')));
            if (result) {
              const address = result.address_components.find(f => f.types.includes('country'));
              if (address) {
                const country = parent.countries.find(f => f.iso_2 === address.short_name);
                if (country) {
                  parent.current_country = country;
                  console.log(`Geo Country: ${JSON.stringify(parent.current_country)}`);

                  this.country_service.changeCountry(parent.current_country)
                }
              }
            }
          },
          error: error => {
            console.log(error);
          }
        });
      }, (error) => {
        console.log(error);
      });

    }
  }

  public setCountry(): void {
    if (!this.current_country) {
      return;
    }

    this.checkout.ChangeCountry(this.current_country).subscribe(() => {

      this.country_service.changeCountry(this.current_country!);
    });

  }

}
