import { NgModule } from '@angular/core';
import { RouterModule, ROUTES } from '@angular/router';
import { RouteBuilderService } from './modules/core/services/route-builder.service';

@NgModule({
  imports: [RouterModule.forRoot([])],
  exports: [RouterModule],
  providers: [
    {
      provide: ROUTES,
      multi: true, 
      deps: [RouteBuilderService],
      useFactory: (builder: RouteBuilderService) => {
        return [
          {
            path: 'login',
            loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
          },
          {
            path: 'forgot-password',
            loadChildren: () => import('./modules/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
          },
          {
            path: 'reset-password',
            loadChildren: () => import('./modules/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
          },
          {
            path: 'dashboard',
            loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
          },
          {
            path: 'staff-dashboard',
            loadChildren: () => import('./modules/staff-dashboard/staff-dashboard.module').then(m => m.StaffDashboardModule)
          },
          {
            path: 'checkout',
            loadChildren: () => import('./modules/checkout/checkout.module').then(m => m.CheckoutModule)
          },
          {
            path: 'staff-registration',
            loadChildren: () => import('./modules/staff-registration/staff-registration.module').then(m => m.StaffRegistrationModule)
          },
          {
            path: 'sparks-foundation',
            loadChildren: () => import('./modules/sparks-foundation/sparks-foundation.module').then(m => m.SparksFoundationModule)
          },
          {
            path: 'blog',
            loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
          },
          {
            path: 'gear',
            loadChildren: () => import('./modules/gear/gear.module').then(m => m.GearModule)
          },
          {
            path: 'services',
            loadChildren: () => import('./modules/services/services.module').then(m => m.ServicesModule)
          },
          {
            path: 'about',
            loadChildren: () => import('./modules/about/about.module').then(m => m.AboutModule)
          },
          {
            path: 'elearning',
            loadChildren: () => import('./modules/elearning/elearning.module').then(m => m.eLearningModule)
          },
          {
            path: 'rowing-camps',
            loadChildren: () => import('./modules/camps/camps.module').then(m => m.CampsModule)
          },
          {
            path: 'jobs',
            loadChildren: () => import('./modules/jobs/jobs.module').then(m => m.JobsModule)
          },
          ...builder.BuildRoutes({
            exclude_prefixes: ['/login', '/forgot-password', '/reset-password', '/dashboard', '/checkout', '/staff-registration', '/sparks-foundation', '/blog', '/gear', '/services', '/about', '/elearning', '/rowing-camps', '/jobs'],
          })
        ];
      }
    }
  ]
})
export class AppRoutingModule {

}
