import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { IdentityService } from '@murdough-solutions/cms-common';
import { Angulartics2GoogleTagManager } from 'angulartics2';
import { getYear } from 'date-fns';
import { component_resolver } from './component-map';
import { MetaService } from './modules/core/services/meta.service';
import { IntercomService } from './services/intercom.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public header_collapsed = true;

    public readonly component_resolver = component_resolver

    public readonly currentYear = getYear(new Date())

    constructor(
        private readonly meta: MetaService,
        public readonly router: Router,
        private readonly intercom: IntercomService,
        private readonly gtm: Angulartics2GoogleTagManager,
        private readonly identity: IdentityService
    ) {
        this.identity.decoded_token$.subscribe(token => {
            this.gtm.setUsername(token?.nameid ?? '')
        });

        this.meta.Initialize();
        this.intercom.boot()
        this.gtm.startTracking()
        
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.header_collapsed = true;
            }
        });
    }

}
