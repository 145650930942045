<cms-loading-overlay></cms-loading-overlay>

<header>
  <nav
    class="navbar navbar-expand-lg navbar-dark"
    [class.bg-dark]="router.url != '/'"
  >
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">Sparks</a>
      <button
        class="navbar-toggler"
        type="button"
        (click)="header_collapsed = !header_collapsed"
        [attr.aria-expanded]="!header_collapsed"
        aria-controls="administration-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse"
        id="navbar-collapsed"
        [ngbCollapse]="header_collapsed"
      >
        <cms-menu
          id="2d11094f-fe2a-476f-a217-49ed0c7ac748"
          [component_resolver]="component_resolver"
          class="ms-auto"
        ></cms-menu>
      </div>
    </div>
  </nav>
</header>
<section id="main-content" class="flex-grow-1">
  <router-outlet></router-outlet>
</section>
<footer>
  <div class="container">
    <div class="row">
      <div class="col-sm-6 order-sm-3 text-center text-sm-right pt-3 pb-3">
        <cms-menu
          id="97cac1d0-9b30-47f9-88b7-8db1d4fb599b"
          [component_resolver]="component_resolver"
        ></cms-menu>
      </div>
      <div class="col-sm-3 order-sm-2 pt-1 pb-1">
        <div>
          <cms-country-select #countrySelect>
            <select
              *ngIf="countrySelect.countries"
              [(ngModel)]="countrySelect.current_country"
              [compareWith]="countrySelect.country_comparer"
              (change)="countrySelect.setCountry()"
              class="form-control"
            >
              <option
                *ngFor="let country of countrySelect.countries"
                [ngValue]="country"
              >
                {{ country.name }}
              </option>
            </select>
          </cms-country-select>
        </div>
      </div>
      <div class="col-sm-3 order-sm-1 text-center text-sm-left pt-3 pb-3">
        <p>Copyright &copy; {{ currentYear }} Sparks Rowing</p>
      </div>
    </div>
  </div>
</footer>
